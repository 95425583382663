#contract {
  min-height: 100vh;
  width: 100%;
  background-color: rgb(102, 154, 250);
  padding: 10px 0;
}

#contract > div {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 42px;
  width: 100%;
  min-height: 91vh;
  background-color: white;
}

#contract > div > select {
  align-self: flex-start;
  font-size: 18px;
  text-align: center;
  color: white;
  font-weight: bold;
  margin: 30px 0 5px 15px;
  width: 150px;
  border: none;
  border-radius: 5px;
  outline: none;
  padding: 5px;
  background-color: rgb(102, 154, 250);
}

#contract #formCont {
  width: 95%;
}

#contract #formCont form > div {
  margin: 25px 0;
  border-radius: 10px;

  padding: 20px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(228, 228, 228);
  justify-content: space-between;
}

#contract #formCont form .blockCont > div {
  margin-bottom: 10px;
}

#contract #formCont form .blockCont > div:nth-child(1) {
  padding-left: 1px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

#contract #formCont form .blockCont > div:nth-child(1) i {
  color: white;
  font-size: 45px;
}

#contract #formCont form .blockCont > div:nth-child(1) .fa-plus {
  font-size: 25px;
  position: absolute;
  color: rgb(139, 139, 139);
  top: 0;
  right: 10px;
  visibility: hidden;
  opacity: 0;

  transition: all 0.4s ease-in-out;
}

.switchIcon {
  visibility: visible !important;
  opacity: 1 !important;
}

#contract #formCont form .blockCont > div:nth-child(1) h4 {
  margin: 0 0 0 8px;
  font-weight: bold;
}

#contract #formCont form .blockCont > div:nth-child(2),
#contract #formCont form .blockCont > div:nth-child(3),
#contract #formCont form .blockCont:nth-child(2) > div:nth-child(4) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 10px 0;
}

#contract #formCont form .blockCont:nth-child(1) > div:nth-child(4) {
  width: 100%;
  height: 0;
  overflow: hidden;

  transition: all 0.4s ease-in-out;
}

#contract #formCont form > div > div > div {
  width: 49%;
}

#contract #formCont form > div > div > div input,
#contract #formCont form > div > div > div select {
  background-color: white !important;
  width: 100%;
  border-radius: 5px;
  border: none;
  outline: none;
  padding: 2px;
  text-align: center;
}

#contract #formCont form .btnAdd {
  height: 0;
}

#contract #formCont form .btnContract {
  height: 0;
}

#contract #formCont form .btnAdd,
#contract #formCont form .btnContract {
  display: flex;
  justify-content: space-between;
  width: 70%;
  overflow: hidden;
  transition: all 0.4s ease-in-out;
}

#contract #formCont form .btnAdd button,
#contract #formCont form .btnContract button {
  border: none !important;
  background-color: rgb(144, 183, 255);
  color: white;
  margin: 15px 0 0 0;
  border-radius: 5px;
  padding: 3px;
  width: 45%;
}

#contract #formCont form .btnAdd button:last-child,
#contract #formCont form .btnContract button:last-child {
  background-color: rgb(139, 139, 139);
}

#contract #formCont form > div > div > div label {
  margin: 0 0 3px 5px;
  font-weight: 600;
  font-size: 16px;
}

#contract .PhoneInputCountry {
  display: none;
}

#contract #formCont form .blockCont:nth-child(2) > div:nth-child(5) {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

#contract .addCont {
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  opacity: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: transparent;

  transition: all 0.7s ease-in-out;
}

#contract .addCont > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(102, 154, 250);
  border-radius: 10px;
  padding: 20px 30px;
}

#contract .addCont > div i {
  font-size: 30px;
  color: green;
  background-color: white;
  padding: 10px;
  border-radius: 50%;
  margin-bottom: 10px;
}

#contract .addCont > div p {
  font-size: 17px;
  font-weight: 650;
  color: white;
}
