.Dir {
  direction: rtl;
}

#question {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 200;
  background-color: rgba(0, 0, 0, 0.7);
}

#question span {
  color: rgb(143, 143, 143);
}

.PhoneInputInput {
  text-align: left !important;
}

.hideAsking {
  display: none;
}

#question .btnCont {
  padding: 4% 0;
  overflow: hidden;
  transition: all 0.5s linear;
  position: relative;
  background-color: rgba(0, 0, 0, 0.7);
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.2s linear;
}

#question .btnCont > div {
  transform: translateY(0);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.2s linear;
  text-align: center;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

#question .btnCont > div > div {
  width: 100%;
  height: 100%;
}

#question .description {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  margin: 0;
  color: white;
  width: 90%;
  padding: 1.7% 0;
  border-radius: 5px;
  font-size: 16px;
}

#question .btnCont span:hover {
  cursor: pointer;
}

.hideQues {
  height: 0% !important;
  transform: translateY(-150%) !important;
}
#question .btnCont > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

#question .btnCont i {
  position: absolute;
  top: 10%;
  right: 8%;
  color: red;
  font-size: 25px;
}

#question a {
  text-decoration: none;
}
#question button {
  margin: 0 15px;
}
#question .btnCont span {
  background-color: white;
  color: black;
}
#question form div:last-child span {
  background-color: white;
}
#question button,
#question a {
  color: white;
  font-weight: bold;
  background-color: darkred;
}
#question button,
#question a,
#question .btnCont span,
#question form div:last-child span {
  font-size: 16px;
  font-weight: bold;
  width: 90px;
  border: none;
  border-radius: 5px;
  padding: 2% 0;
}

#question form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 0;
  width: fit-content;
  text-align: center;
  width: 87%;
  overflow: hidden;
  transition: all 0.2s linear;
  transform: translateY(150%);
}

.hideForm {
  height: 100% !important;
  transform: translateY(0%) !important;
}

#question form .mobile {
  margin: 4% 0;
}

#question form .actionsBtns {
  margin-top: 8px;
}

#question form .mobile span {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0% 3%;
  position: absolute;
  background-color: rgb(214, 214, 214);
  height: 100%;
  top: 0%;
  left: 0%;
  color: black;
  width: fit-content;
  z-index: 1;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  font-weight: bold;
}

.PhoneInputCountry {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  width: 15%;
  margin: 0 !important;
  background-color: rgb(226, 226, 226) !important;
  justify-content: center !important;
  align-items: center !important;
}

.PhoneInputCountrySelectArrow {
  display: none;
}
#question form input {
  outline: none;
  overflow: hidden;
  border: none;
  font-size: 16px;
  width: 100%;
  padding: 1% 2%;
  text-align: center;
  border-radius: 5px;
}
#question form input:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
