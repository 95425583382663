body {
  margin: 0;
  padding: 0;
}

.swiper {
  width: 100%;
  height: 100vh;
  position: absolute;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100vh;
}

.swiper-3d,
.swiper-3d.swiper-css-mode .swiper-wrapper {
  perspective: 13000px !important;
}
